import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import '../index.scss';

const AGGuidesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ag-page'} game="ag">
      <ul className="breadcrumb">
        <li>
          <Link to="/artery-gear">Artery Gear</Link>
        </li>
        <li className="divider">/</li>
        <li>Guides</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/arterygear/categories/category_guides.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Guides</h1>
        </div>
      </div>
      <div className="page-content">
        <div className="banner eos">
          <div className="cta">
            <h4>Artery Gear - End of Service</h4>
            <p>
              Sadly, the dreaded day has come. On the 16th July 2024,{' '}
              <strong>
                Bilibili announced that Artery Gear will receive one last update
                and then the game will be put into maintenance mode
              </strong>{' '}
              - which means that within few months at most, the game will be
              closed down. You can read the note{' '}
              <a
                href="https://www.bilibili.com/opus/954322835327680513?spm_id_from=333.999.0.0"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
            <p>
              We will be reducing our support for the game and removing most of
              the content - we will only keep a snapshot of the last edition of
              our tier list. Thank you for the being with us!
            </p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AGGuidesPage;

export const Head: React.FC = () => (
  <Seo
    title="Guides | Artery Gear | Prydwen Institute"
    description="Guides for Artery Gear Global and CN versions."
  />
);
